import { createObservableDataAction, IAction, IActionContext, sendRequest } from '@msdyn365-commerce/core';
import { SubmitHelpfulnessVoteInput } from './inputs/submit-helpfulness-vote-input';

async function submitHelpfulnessVote(input: SubmitHelpfulnessVoteInput, ctx: IActionContext): Promise<boolean> {
    if (!inputIsValid(input)) {
        throw new Error(
            `[SubmitHelpfulnessVote] Invalid input. ReviewId=${input.reviewId},tenantId=${input.tenantId}, locale= ${
                input.locale
            },`
        );
    }

    const requestUrl =
        `${input.serviceEndpoint}/v2.0/votes/review/${input.reviewId}` +
        `?tenantId=${input.tenantId}` +
        `&locale=${input.locale}`;

    const requestBody = {
        isHelpful: !!input.isHelpful
    };

    const requestHeader = {
        headers: {
            Authorization: `id_token ${input.authToken}`
        }
    };

    return sendRequest<boolean>(requestUrl, 'post', requestBody, requestHeader)
        .then(response => {
            return response.status === 200 || response.status === 201;
        })
        .catch((error) => {
            ctx.trace(`[SubmitHelpfulnessVote] Failed to vote on a review`);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug(`[SubmitHelpfulnessVote] Failed to vote on a review`);
            return false;
        });
}

function inputIsValid(input: SubmitHelpfulnessVoteInput): boolean {
    return (
        input &&
        input.reviewId.length > 0 &&
        input.tenantId.length > 0 &&
        input.locale.length > 0 &&
        input.authToken.length > 0
    );
}

export const submitHelpfulnessVoteDataAction =  createObservableDataAction({
    id: '@msdyn365-commerce-modules/ratings-reviews/submit-helpfulness-vote',
    action: <IAction<boolean>>submitHelpfulnessVote
});

export default submitHelpfulnessVoteDataAction;