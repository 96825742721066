import ReviewsList from './reviews-list';
import ReviewsListView from './reviews-list.view';

export * from './components/components';
export * from './components/report-review-modal';
export * from './components/review-card';
export * from './reviews-list.props.autogenerated';
export * from './reviews-list.data';
export * from './reviews-list';
export * from './reviews-list.view';

export {
    ReviewsList,
    ReviewsListView
};