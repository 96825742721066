import WriteReview from './write-review';
import WriteReviewView from './write-review.view';

export { IWriteReviewConfig, IWriteReviewResources, IWriteReviewProps } from './write-review.props.autogenerated';
export * from './write-review.data';
export * from './write-review';
export * from './write-review.view';

export {
    WriteReview,
    WriteReviewView
};