import RatingsHistogram from './ratings-histogram';
import RatingsHistogramView from './ratings-histogram.view';

export * from './component/components';
export { IRatingsHistogramConfig, IRatingsHistogramResources, IRatingsHistogramProps } from './ratings-histogram.props.autogenerated';
export * from './ratings-histogram.data';
export * from './ratings-histogram';
export * from './ratings-histogram.view';

export {
    RatingsHistogram,
    RatingsHistogramView
};